/* Status Bar */
.status-bar {
  display: flex;
  justify-content: center;
  gap: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.status-tab {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}

.status-tab.active {
  font-weight: 700;
  color: #000;
}

.status-tab:hover {
  color: #555;
}

.status-underline {
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 100%;
  height: 3px;
  background-color:#FF4D00;
  border-radius: 2px;
  transform: translateX(-50%);
}
/* .booking-list {
  margin-top: 15px;
}

.booking-card {
  background:#273B60;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
} */

/* Status Bar */
.status-bar {
  display: flex;
  justify-content: center;
  gap: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.status-tab {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}

.status-tab.active {
  font-weight: 700;
  color: #000;
}

.status-tab:hover {
  color: #555;
}

.status-underline {
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 100%;
  height: 3px;
  background-color: #ffcc00;
  border-radius: 2px;
  transform: translateX(-50%);
}
