/* order Card */
.order-list {
    margin-top: 15px;
  }
  
  .order-card {
    background: #273B60;
    color: white;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .modal-orderhist-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  } */
  
  /* Orange Arrow Button */
  .arrow-button {
    background: #F16427;
    border: none;
    color: white;
    font-size: 20px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .arrow-button:hover {
    background: #F16427;
  }
  
  /* Modal Styling */
  /* .modal-orderhist-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
   */
  /* .modal-orderhist-content {
    background: white;
    box-shadow: 10px 10px 15px rgba(14, 14, 14, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    text-align: center;
  }
   */
  /* Order Status */
  /* .order-status {
    background: #F16427;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;
  } */
  
  /* Modal Sections */
  .modal-section {
    background:white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-bottom: 5px;
    border-radius: 8px;
    text-align: left;
  }
  
  .modal-section h3 {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .modal-section p {
    font-size: 13px;
    color: #555;
    margin: 2px 0;
  }
  
  /* Action Buttons */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .decline-btn {
    background: red;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    flex: 1;
    margin-right: 5px;
  }
  
  .closing-btn {
    background: red;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    flex: 1;
    margin-right: 5px;
  }
  .decline-btn:hover {
    background: #d43f3f;
  }
  
  .accept-btn {
    background: #28a745;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    flex: 1;
    margin-left: 5px;
  }
  
  .accept-btn:hover {
    background: #218838;
  }
  
  
  h3.modal-h3{
    color: #273B60;
    font-size: 20px;
  }
  /* order Status */
  .order-status {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  /* Green for Completed */
  .order-status.completed {
    background: #28a745;
    color: white;
  }
  
  /* Orange for Pending */
  .order-status.pending {
    background: #ff7043;
    color: white;
  }
  
  /* Yellow for In-Progress */
  .order-status.in-progress {
    background: yellow;
    color: black; /* Ensure text is readable */
  }
  
  .headingnew{
    color: #273B60;
    text-decoration: none;
  }
  
  .customer-image{
    width: 50px;
    height: 50px;
  }
  /* Modal Styling */
.modal-orderhist-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-orderhist-content {
    background: white;
    box-shadow: 10px 10px 15px rgba(14, 14, 14, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    text-align: center;
  }