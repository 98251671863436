.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Make the container full height of the viewport */
}

.loader {
  position: relative;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left: 3px solid #f16427; /* Theme color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.inner,
.middle,
.outer {
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.inner {
  border-top-color: #f16427; /* Theme color */
  animation: inner-spin 1s linear infinite;
}

.middle {
  border-top-color: #f16427; /* Theme color */
  animation: middle-spin 1s linear infinite;
}

.outer {
  border-top-color: #f16427; /* Theme color */
  animation: outer-spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes inner-spin {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes middle-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes outer-spin {
  to {
    transform: rotate(-360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this value as needed */
}

.loader {
  /* Your existing loader styles */
}
