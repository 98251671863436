.console-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .console-heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .console-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .console-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .console-card:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .console-icon {
    font-size: 24px;
    color: #F16427; /* Icon color */
    margin-bottom: 10px;
  }
  
  .console-title {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  