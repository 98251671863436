.download-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
  }
  
  .download-left {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .mobile-image {
    width: 100%;
    max-width: 300px;
  }
  
  .download-right {
    flex: 2;
    text-align: left;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 1rem;
  }
  
  .heading {
    color: #273b60;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .sub-text {
    color: #666;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .download-text {
    color:black;
    font-size: 1.5rem;
    font-weight: bold;
    /* margin-bottom: 1rem; */
    font-size: 3rem;
    font-weight: 800;
    color:black;
  }
  
  .download-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 0px;
    /* margin-bottom: 1rem; */
  }
  
  .store-btn {
    width: 150px;
  }
  
  .qr-code {
    width: 60px;
    margin-top: 3rem;
  }
  