.claim-form-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(12, 11, 11, 0.1);
    margin-top: 50px;
  }
  
  .claim-form-container h2 {
    text-align: center;
    font-size: 25px;
    color:#FF4D00;
    ;
  }
  .claim-form-container h3 {
    text-align: center;
    font-size: 20px;
    color:#273B60;
    /* font-weight: 500; */
    
  }
  
  .claim-form-container input, 
  .claim-form-container button {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
  }
  
  .claim-form-container button {
    background-color: #273B60;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* .claim-form-container button:hover {
    background-color:white;
    color: #273B60;
  } */
  
  /* .claim-form-container p {
    font-size: 14px;
    text-align: center;
  } */
  