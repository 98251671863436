/* Container for the whole section */
.how-tajjam-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    text-align: center;
    padding: 2rem 1rem;
    background-color: #f8f9fc;
  }
  
  /* Heading Styles */
  .how-tajjam-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .orange-text {
    color: #f76300; /* Orange color */
  }
  
  .blue-text {
    color: #273B60; /* Blue color */
  }
  
  /* Steps Container */
  .how-tajjam-steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 2rem;
    padding: 0 2rem;
  }
  
  /* Horizontal line between steps */
  .how-tajjam-steps::before {
    content: "";
    position: absolute;
    top: 2.25rem; /* Aligns with the center of step circles */
    left: 10%;
    right: 10%;
    border-top: 2px solid #ccc;
    z-index: 0;
  }
  
  /* Individual Step */
  .step {
    flex: 1;
    text-align: center;
    position: relative;
    background-color: #f8f9fc;
    z-index: 1;
  }
  
  .step-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    background-color: #fff;
    border: 2px solid #273B60; /* Blue border */
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight:600;
    color: #273B60;
    margin: 0 auto 1rem;
  }
  

  
  .step h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color:#273B60;
  }
  