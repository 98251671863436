.font-poppins {
    font-family: 'Poppins', sans-serif;
  }
  
  .services-container {
    padding: 20px;
    margin-top: 100px;
  }
  
  .business-section {
    margin-bottom: 40px; /* Space between each business */
    border: 1px solid #ccc; /* Optional: border around each business section */
    padding: 15px; /* Optional: padding inside the business section */
    border-radius: 8px; /* Optional: rounded corners */
    background-color: #f9f9f9; /* Optional: background color */
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of service cards */
    gap: 15px; /* Space between service cards */
  }
  
  /* Service Card Styles */
  .service-card {
    width: 350px; /* Fixed width for all cards */
    height: 500px; /* Fixed height for all cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow for card */
    border-radius: 5px; /* Optional: rounded corners for cards */
    background: white; /* Card background */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure elements inside are spaced out */
    padding: 10px; /* Padding inside the card */
  }
  
  /* Additional styling for service card elements */
  .service-card img {
    width: 100%; /* Image takes full width of card */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: rounded corners for images */
  }
  
  .service-card h3 {
    font-size: 16px; /* Title size */
    margin: 5px 0; /* Margin for title */
  }
  
  .service-card p {
    font-size: 14px; /* Description size */
    margin: 5px 0; /* Margin for description */
  }
  
  .service-card .price {
    font-weight: bold; /* Price styling */
  }
  .search-container{
    margin-top: 200px;
    margin-left: 50px;
  }