/* Header Container */
.head-container {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px;
    /* background-color: #f15d1e; */
    width: 50%;
    border-radius: 20%;
    height: 30%;
    

  }
  
  /* Location Component */
  .location {
    color: white;
    font-weight: bold;
    cursor: pointer;
    /* background-color: #37475a; */
    padding: 8px 12px;
    border-radius: 15px;
  }

  .closing-btn{
    display: flex;
    /* justify-content: right;
    
    align-items:right; */
    justify-content: space-evenly;
    width: 100%;
    background-color:rgb(236, 230, 230);
  }
  .close{
    display: flex;
    justify-content:right;
    align-items: right;
  }
  /* Search Bar Styling */
  .searching-bar {
    display: flex;
    /* flex: 1; */
    /* max-width: 800px; */
    background-color: white;
    border-radius: 30px;
    /* height: 30%; */
  }
  
  .searching-bar input {
    flex: 1;
    padding: 2px;
    border: none;
    border-radius: 30px;
    outline: none;
    /* border-radius: 35%; */

  }
  
  .searching-bar button {
    padding: 10px;
    /* background-color: #febd69; */
    border: none;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    height: 50%;
  }
  
  /* Modal Content */
  .modal-header-content {
    background-color: white;
    width: 350px;
    margin: 100px auto;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    text-align: center;
    
  font-family: Arial, sans-serif;

  }
  
  .postal-input {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
  }
  
  .postal-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .postal-input button {
    padding: 8px 12px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .done-modal-btn {
    background-color: #F16427;
    color: white;
    border: none;
    padding: 5px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
  }
  



  .ship-text{
    margin-bottom:0px;
  }


  @media (max-width: 768px) {
.location{
  font-size: 10px;
  width: 20%;
}
input.search-input{
  font-size: 13px;
}

  }
  @media (max-width: 425px) {
    .location{
      font-size: 6px;
      width: 15%;
    }
    input.search-input{
      font-size: 10px;
    }
    
      }