.history-container{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
    flex-direction: column;
}
.tabs{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.items{
    margin-bottom: none;
    text-decoration: none;
}
li.items:hover{
    text-decoration: none;
}
.info-div{
    margin-bottom: 10px;
}
p{
    margin-bottom: 0px;
}
.status{
    color: black;
    text-decoration: none;
}
.status-div{
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    align-items: center;
}