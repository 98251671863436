/* fonts.css */
.roboto-regular {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
  
  .roboto-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
  }
  
  .open-sans-regular {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  
  .open-sans-semi-bold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }
  
  .lora-regular {
    font-family: 'Lora', serif;
    font-weight: 400;
  }
  
  .lora-bold {
    font-family: 'Lora', serif;
    font-weight: 700;
  }
  