.service-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: left;
  height: 100vh;
  overflow: scroll;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modal-service {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-fields label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-fields input,
.modal-fields textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-fields textarea {
  resize: vertical;
  min-height: 50px;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.delete-btn {
  background-color:  #ff4d00 ;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.update-btn {
  background-color: #273b60 ;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}
.flexxxx {
  display: flex;
  justify-content: space-between;
}
.closingggg-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  margin-bottom: 1em;
}
.modal-service-image {
  width: 300px;
  height: 200px;
}
