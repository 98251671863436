.confirmation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
    padding: 20px;
  }
  
  .thank-you-logo {
    font-family: 'Brush Script MT', cursive; 
    /* Similar script-like font */
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  p.order-text {
    font-size: 1rem;
    color: #333;
    max-width: 600px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  .return-home-button {
    background-color:  #273B60;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    /* text-transform: uppercase; */
    margin-bottom: 20px;
  }
  
  .return-home-button:hover {
    background-color:  #273B60;
  }
  
  .footer-text {
    margin-top: 50px;
    font-size: 0.875rem;
    color: #888;
  }
  
  .footer-text a {
    color: #000;
    text-decoration: none;
  }
  
  .footer-text a:hover {
    text-decoration: underline;
  }
  