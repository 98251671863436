.app-container {
    display: flex;
    height: 100vh;
    background-color:white; /* Main background color */
}

/* Main Content */
.main-content {
  flex: 1; /* Take the remaining space after the sidebar */
  padding: 20px;
  overflow-y: auto; /* Handle scroll for main content */
  color: #3E5879; /* Text color for contrast */
  margin-left: 20px;
}

.dashboard-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
}

.left-panel {
  flex: 1;
max-width: 500px;
}

.right-panel {
  flex: 1;
  background: #f8f9fa;
  min-height: 200px;
  border-radius: 10px;
  max-width: 500px;
}


.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
