/* Container */
.features-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 1rem;
  }
  
  /* Main Headings */
  .features-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #f76300; /* Orange color */
    margin-bottom: 0.5rem;
    font-weight: bolder;
    font-size: 4rem;
 text-decoration: none;
    font-weight: 900;
  }
  
  .features-cust-subheading {
    font-size: 1.2rem;
    font-weight: 800;
    /* color: #273b60; */
     /* Dark Blue */
    /* margin-bottom: 2rem; */
    color: #f76300; /* Blue color */
    /* font-size: 1.8rem; */
    /* margin: 10px 0; */
    font-size: 1.5rem;
    text-decoration: none;
       font-weight: 700;
       
  }
  
  /* Grid Layout */
  .features-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 2rem;
    justify-items: start;
  }
  
  /* Individual Feature Item */
  .feature-item {
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    /* Align items vertically */
    gap: 1.5rem; /* Space between image and text */
    text-align: left;
  }
  .feature-item1 {
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* Align items vertically */
    gap: 1.5rem; /* Space between image and text */
    text-align: left;
  }
  
  /* Images */
  .feature-icon {
    width: 200px; /* Slightly bigger images */
    height: 200px;
    object-fit: contain;
  }
  
  /* Feature Title */
  .feature-title {
    font-size: 1.4rem;
    font-weight: 700;
    /* color: #273b60; */
     /* Dark Blue for headings */
    margin-bottom: 0.5rem;
    color: #003366; /* Blue color */
    /* font-size: 1.8rem; */
    /* margin: 10px 0; */
    font-size: 1.5rem;
    text-decoration: none;
       font-weight: 900;
       /* text-align: left; */
  }
  
  /* Feature Text */
  .feature-text {
    font-size: 1rem;
    color: #666;
    font-weight: 400;
    line-height: 1.5;
  }
  
  /* Last Row Item */
  .features-grid .feature-item:nth-child(5) {
    grid-column: span 2; /* Centering the last row item */
    justify-content: center;
  }
  
  .business-text{
    margin-top: 80px;
  }