.slots-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.slots-table th,
.slots-table td {
  border: 1px solid   #273B60;
  padding: 10px;
  text-align: center;
}

.slots-table th {
  background-color:  #273B60;
color: white;
  font-weight: bold;
}

.slots-table td input[type="radio"] {
  cursor: pointer;
}

.slots-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.slots-table tr:hover {
  background-color: #f1f1f1;
}

.date{
  color:   #273B60;
  text-decoration: none;
  
}

.book-now-btn{
  margin-top: 20px;
  padding: 10px;
  /* background-color: black; */
  background-color:  #273B60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 20%;
}
.btn-book{
  display: flex;
  justify-content: right;
  align-items: right;
}
/* Close button styles */
.close-btn {
  position: absolute; /* Position at the corner of the modal */
  top: 10px;
  right: 10px;
  background: none; /* No background */
  border: none; /* No border */
  font-size: 24px; /* Large font for the cross */
  color: #333; /* Cross color */
  cursor: pointer;
}

.close-btn:hover {
  color: #273B60; /* Change color on hover for effect */
}

.close-btn:focus {
  outline: none; /* Remove focus outline */
}
