/* CheckoutPage.css */
.checkout-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    /* margin-top: 100px; */
    flex-direction: row-reverse;
  }
  
  .order-details {
    flex: 1;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
  }
  
  .return-link {
    text-decoration: none;
    color: black;
    font-size: 14px;
  }
  
  h2 {
    margin: 10px 0;
    font-size: 20px;
  }
  .total{
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items:flex-end;
  }
  p.item-info{
    font-size: 15px;
  }
  .order-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .product-image {
    width: 80px;
    height: auto;
    border: 1px solid #ddd;
  }
  
  .order-details p {
    margin: 10px 0;
  }
  
  .total-text {
    /* font-weight: bold; */
    font-size: 16px;
  }
  .fields{
    display: flex;
    justify-content: space-between;
  }
  .checkout-form {
    flex: 1;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
  }
  
  label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .input-field{
    /* width: 200%; */
    padding: 8px;

  }
  
  .proceed-button {
    margin-top: 20px;
    padding: 10px;
    /* background-color: black; */
    background-color:  #273B60;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
/* Additional CSS */
.payment-details {
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ddd;
    font-size: 14px;
    text-align: center;
  }
  
  .payment-details h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .payment-details p {
    margin: 0;
    font-size: 14px;
  }
    