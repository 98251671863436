.add-service-container {
  max-width: 600px;
  margin: 2em auto;
  background-color: #f9f9f9;
  padding: 2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em !important;
  font-weight: bolder;
  color: #273b60 !important;
  text-decoration: none;
}

.add-service-content {
  width: 90%;
  max-width: 500px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.add-service-header {
  display: flex;
  align-items: center;
  gap: 1em;
}

.section-content-title {
  font-size: 1.3em !important;
  font-weight: bold;
  color: #273b60 !important;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.add-service-header h1 {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.collapsible-section {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.collapsible-section h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-section .input-group {
  display: none;
  padding: 10px 0;
}

.collapsible-section.open .input-group {
  display: block;
}

.input-field {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.form-group {
  margin-bottom: 15px;
}

.form-group-label{
  color: #273b60 !important;
}
.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.uploaded-image {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
}

.add-service-button {
  width: 100%;
  padding:0.5em;
  background-color: #ff4d00;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.3em !important;
}

.add-service-button:hover {
  background-color: #e84300;
}

.business-services-button{
  width: 100%;
  padding: 0.5em;
  background-color: #273b60;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.3em !important;
}

.business-services-button:hover {
  background-color: #1d2c49;
}
.buttons-group{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.collapsible-section .input-group {
  display: none;
}

.collapsible-section.open .input-group {
  display: block;
}
.collapsible-section .input-group {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  opacity: 0;
}

.collapsible-section.open .input-group {
  max-height: 1000px; /* Set a high enough value for expanded height */
  opacity: 1;
}
