/* Date Picker Tabs */
.date-picker-tabs {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    color: #273B60;
  }
  
  /* Tabs */
  .date-tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ddd;
    margin-bottom: 10px;
  }
  .date-content{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* width: 200%; */
  }
  .date-tab {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #555;
  }
  
  .date-tab.active {
    color: #000;
    border-bottom: 4px solid #F16427;
  }
  
  /* Date Selection */
  .today-date {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
  
  .today-date label {
    font-size: 14px;
    color: #777;
  }
  
  .today-date input {
    padding: 8px;
    font-size: 14px;
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  /* .date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  h1.sel{
    color: #F16427;
    text-decoration: none;
  }