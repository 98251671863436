.profile-container {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 100px;
  }
  
  .profile-header {
    text-align: center;
    color: #273B60;
    font-size: 24px;
  }
  
  .profile-picture {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    background-color: #d1d5db; /* Tailwind's bg-gray-300 equivalent */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    color: #273B60;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: black;
  }
  
  .change-password-link {
    color: #273B60;
    text-align: right;
    display: block;
    margin-bottom: 20px;
  }
  
  .update-button {
    /* width: 100%; */
    background-color:  #273B60;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 40%;
  }
  .profile-container {
    max-width: 400px;
    /* margin: 0 auto; */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color:white;
  }
  
  .profile-header {
    text-align: center;
    color: #273B60;
    font-weight: 800;
    line-height: 2;
  }
  
  .profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    /* background-color: #eaeaea;
    border-radius: 50%;
    padding: 20px; */
    width: 100px;
    /* height: 100px; */
    margin-bottom: 10px;
    /* border-radius: 50%; */
    /* border: 2px solid black; */
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .name{
    text-align: center;
    font-style: italic;
    color: #273B60;
  }
  .btns{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
    align-items: center;
    /* justify-content: space-between; */
  }