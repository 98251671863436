.booking-summary {
    background: #A3D1E0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

/* .booking-title {
    font-size: 18px;
    font-weight: bold;
    color: #2C3E50;
    text-align: center;
    margin-bottom: 15px;
} */

/* Earnings Box */
.summary-box {
    background: #2C3E50;
    color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.earning-icon {
    color: #f39c12; /* Orange Icon */
    font-size: 30px;
}

.earning-details {
    flex-grow: 1;
    margin-left: 10px;
}

.earning-amount {
    font-size: 22px;
    font-weight: bold;
    /* color: #2C3E50; */
}

.earning-text {
    font-size: 14px;
    /* color: gray; */
    display: block;
}

.earning-label {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

/* Booking Status */
.status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.status-box {
    text-align: center;
    flex: 1;
    padding: 10px;
    background: #2C3E50;
    color: white;
    border-radius: 10px;
    position: relative;
}

/* Icons for Pending, In-Progress, Completed */
.status-icon {
    font-size: 20px;
    margin-bottom: 5px;
}

.pending-icon {
    color: #f39c12; /* Orange for Pending */
}

.inprogress-icon {
    color: #3498db; /* Blue for In-Progress */
    animation: spin 2s linear infinite; /* Rotating animation */
}

.completed-icon {
    color: #27ae60; /* Green for Completed */
}

/* Add animation for in-progress spinner */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.status-count {
    font-size: 20px;
    font-weight: bold;
    color: white;
}

.status-label {
    font-size: 14px;
    /* color: gray; */
    display: block;
}

.arrow-icon-small {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #f39c12;
    font-size: 16px;
}

/* Bookings Section */
.bookings-section {
    /* background: #F8F9FA; */
    background: #2C3E50;
    color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 20px;
}

/* .bookings-section:hover {
    background: #e8e8e8;
} */

.bookings-icon {
    color: #f39c12;
    font-size: 30px;
    margin-right: 10px;
}

.bookings-content {
    flex-grow: 1;
}

.bookings-title {
    font-size: 18px;
    font-weight: bold;
}

.bookings-text {
    font-size: 14px;
    /* color: gray; */
    color: white;
}

.arrow-icon {
    color: #f39c12;
    font-size: 22px;
}
