/* Inventory Page Styles */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); */

.inventory-container {
  display: flex;
}

.inventory-content {
  flex-grow: 1;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}



/* .roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
} */

/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-icon {
  color: gray;
}

/* Category Tabs */
.category-tabs {
  margin-bottom: 20px;
}

.tab-item {
  font-weight: bold;
  text-transform: uppercase;
}

.MuiTabs-indicator {
  background-color: orange !important;
}

/* Table Styling */
.inventory-table {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  border: 10px solid #A3D1E0;
}

/* Apply Background Color to Table Header */
.table-header {
  background-color: #2C3E50 !important;
  color: white !important;
  /* font-family: 'Roboto', sans-serif; */
  /* font-weight: 700; */
}



/* Table Cells */
.table-cell {
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 400;
}

/* Quantity Input */
.quantity-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-input {
  width: 80px;
}

.plus-icon {
  color: #1976d2;
}

/* Update Button */
.update-button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.update-button {
  width: 250px;
  background-color: #F16427 !important;
  color: white !important;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 700 !important;
}

.update-button:hover {
  background-color: #d04b1c !important;
}

.inventory-title{
  color: #2C3E50;
  text-align: center;
  font-weight: 800;
  /* font-family: 'Roboto', sans-serif; */
}

.inv-image{
  width: 400px;
  height: 300px;
}
.table-header{
  font-size: 30px;
}