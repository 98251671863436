.order-summary {
    background: #A3D1E0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;

}



/* Earnings Box */
.summary-box {
    background: #2C3E50;
    color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.earning-icon {
    color: #f39c12; /* Orange Icon */
    font-size: 30px;
}

.earning-details {
    flex-grow: 1;
    margin-left: 10px;
}

.earning-amount {
    font-size: 22px;
    font-weight: bold;
    /* color: #2C3E50; */
}

.earning-text {
    font-size: 14px;
    /* color: gray; */
    display: block;
}

.earning-label {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}

/* Order Status */
.status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.status-box {
    text-align: center;
    flex: 1;
    padding: 10px;
    background: #2C3E50;
    border-radius: 10px;
    position: relative;
    color: white;
    margin-right: 10px;
}

.status-count {
    font-size: 20px;
    font-weight: bold;
    color: #2C3E50;
}

.status-label {
    font-size: 20px;
    font-weight: 500;
    /* color: gray; */
    color: white;
    display: block;
}

.arrow-icon-small {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #f39c12;
    font-size: 16px;
}

/* Orders Section */
.orders-section {
    background: #2C3E50;
    color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 20px;
}



.orders-icon {
    color: #f39c12;
    font-size: 30px;
    margin-right: 10px;
}

.orders-content {
    flex-grow: 1;
}

.orders-title {
    font-size: 18px;
    font-weight: bold;
}

.orders-text {
    font-size: 14px;
    color:white;
}

.arrow-icon {
    color: #f39c12;
    font-size: 22px;
}
