.footer {
    background: linear-gradient(180deg, #1B2943 0%, #233556 100%);
    color: white;
    padding: 40px;
    font-family: Arial, sans-serif;
    width:100%;
    /* width: 120%; */
  }
  
  
  
  .footer-links {
    border-top: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
  }
  
  .link-section h1 {
    /* font-size: 20px; */
    font-weight:700;
    margin-bottom: 10px;
    font-size: 25px;
    color: white;
    font-family: Inter;

  }
  .apps{
    display: flex;
    flex-direction: column;
  }
  .qr-icon{
    width: 50px;
    margin: 5px;
  }
  .up{
    display: flex;
    justify-content: space-between;
  }
  .qr{
    display: flex;
    justify-content: space-between;
    align-items: center;


  }
  p.apptags{
    color: white;
    text-decoration: none;
    font-weight: lighter;
    font-family: Inter;
    font-size: 15px;
  }
  .app-links{
    display: flex;

  }
  .link-section a {
    font-family: Inter;



    display: block;
    color: white;
    text-decoration: none;
    font-weight: lighter;

    font-size: 15px;
    margin-bottom: 10px;
  }
  
  .app-links img {
    width: 100px;
    margin: 5px;
  }
  
  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid #E6E6E6;
    padding-top: 20px;
  }
  
  /* .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid #34495e;
    padding-top: 20px;
  } */
  .social-icons{
    margin-bottom: 20px;
  }
  .social-icons a {
    margin: 0 5px;
    font-size: 24px;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid white;
    border-radius: 50%;
    transition: color 0.3s ease, background-color 0.3s ease;
  
  }
  
  .social-icons a:hover {
    color: #1B2943;
    background-color: white;
  }
  
  .footer-legal p {
    font-size: 12px;
    color: white;
  }
  p.social-contacts{
    color: white;
    font-size: 20px;
    font-style: bolder;
    text-align: center;
  }


  .subscription-row {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* background: linear-gradient(180deg, #1B2943 0%, #233556 100%); */
    padding: 20px;
    gap: 20px;
  }
  
  .subscription-field {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 5px;
  }
  
  .subscription-field label {
    color: #FFFFFF;
    font-size: 14px;
  }
  
  .subscription-field input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 250px;
    margin-right: 10px;
  }
  .label{
    display: flex;
    flex-direction: column;
  }
  .signup-button {
    padding: 10px 15px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 50px;
  }
  
  .subscription-message {
    margin-top: 50px;
    color: white;
    font-size: 20px;
    font-style: bolder;
    text-align: center;
    margin-left: 30px;
    white-space: nowrap;
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    .footer{
      padding: 30px;
    }
    .subscription-field input{
      width: 150px;
    }
    .signup-button{
      font-size: 10px;
    }
    .subscription-message{
      font-size: 12px;
    }
    /* .link-section h1{
      font-size: 17px;
    }
    .link-section a{
      font-size: 10px;
    }
    .social-icons a{
      width: 30px;
      height: 30px;
      font-size: 18px;
    } */
  }

  @media (max-width: 768px) {
    .footer{
      padding: 20px;
    }
    .subscription-field input{
      width: 120px;
    }
    .signup-button{
      font-size: 8px;
    }
    .subscription-message{
      font-size: 8px;
    }
    .link-section h1{
      font-size: 17px;
    }
    .link-section a{
      font-size: 10px;
    }
    .social-icons a{
      width: 30px;
      height: 30px;
      font-size: 18px;
    }
  }

  @media (max-width: 425px) {
    .footer{
      padding: 10px;
    }
    .subscription-row{
      gap: 5px;
    }
    .subscription-field input{
      width: 50px;
      font-size: 8px;
    }
    .signup-button{
      font-size: 3px;
    }
    .subscription-message{
      font-size: 5px;
    }
    .link-section h1{
      font-size: 12px;
    }
    .link-section a{
      font-size: 8px;
    }
    .social-icons a{
      width: 25px;
      height: 25px;
      font-size: 12px;
    }
    .subscription-field label{
      font-size: 10px;
    }
    .qr-icon{
      width: 30px;
    }
    p.apptags{
      font-size: 10px;
    }
    .qr{
      width: 92%;
    }
    .app-links img{
      width: 50px;
    }
    p.social-contacts{
      font-size: 15px;
    }
    .footer-legal p{
      font-size:8px;
    }
  }