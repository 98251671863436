.services-main-container{
  display: flex;
  padding: 2em;
  gap: 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.services-head {
  margin-top: 1em;
  text-decoration: none;
  color: #273b60;
  font-weight: bold;
  font-size: 2em;
}

.services-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  margin-top: 0em;
}

.no-services-found img {
  height: 50vh;
}

.no-services-found p {
  font-size: 1.2em;
  color: #555;
}

.services{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1em;
}

.business-service-card{
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(122, 117, 117, 0.5);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:1em;
}

.business-service-info{
  border-radius: 10px;
  width: 100% !important;
}

.business-service-image{
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px;
}

.business-service-image img{
  width: 100% !important;
  height: 20vh;
  object-fit: cover;
  border-radius: 10px;
}

.business-service-details,
.business-service-header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  width: 100%;

}

.service-name{
  color: #273b60;
  font-size: 1.1em;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: bolder !important;
  margin-top: 0.5em;
}

.service-description{
  color: gray;
  font-size: 14px;
  margin: 5px 0;
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-y: auto !important; /* Add vertical scroll for overflow */
  max-height: 4.5em; /* Limit the height */
  line-height: 1.5em; /* Adjust line height */
  padding-right: 5px; /* Add padding for scroll visibility */
}

.business-service-info-details p{
  color: #273b60;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.service-actions{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
  color:#273b60;
}

 h1{
    color: orange;
    text-align: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    text-decoration: underline;
 }

  /* Service Card */
  .service-card {
    display: flex;
    align-items: center;
    background: white;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .service-image {
  
    height: 100px;
    border-radius: 8px;
    margin-right: 16px;
  }
  
  .service-info {
    flex: 1;
    border-top: 2px solid orange;
  }
  
  .service-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .service-description,
  .service-price,
  .service-discounted {
    margin: 4px 0;
  }
  
  .service-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .edit-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  
  .ratings {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .like-button,
  .dislike-button {
    font-size: 14px;
    cursor: pointer;
  }
  
  .availability {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .toggle-switch {
    cursor: pointer;
  }
  

.btn {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #ff4d00 !important;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  font-size: 1.1em;
}

.btn:hover {
  background-color: #e84300;
}

