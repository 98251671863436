@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.ml-container {
  display: flex;
  /* width: 100%; */
}

.ml-main-content {
  flex: 1;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ml-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.headingnew {
  font-size: 28px;
  font-weight: 700;
  color: #2C3E50;
  text-transform: uppercase;
}

.ml-img {
  height: 100px;
}

/* Table Styling */
.ml-product-table-container {
  width: 80%;
  margin-top: 20px;
}

.ml-product-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.ml-product-table th, .ml-product-table td {
  padding: 12px 20px;
  text-align: left;
}

.ml-product-table th {
  background-color: #2C3E50;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}

.ml-product-table tr {
  transition: background 0.3s ease-in-out;
}

.ml-product-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ml-product-table tr:hover {
  background-color: #e0e0e0;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .ml-product-table-container {
    width: 100%;
    overflow-x: auto;
  }

  .ml-product-table th, .ml-product-table td {
    padding: 10px;
  }
}


.dating{
  width: 100%;
}