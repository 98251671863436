.custom-banner-container {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .custom-banner-text {
    flex: 1;
    /* margin-right: 20px; */
  }
  
  .custom-banner-heading-primary {
    color: #f97316; /* Orange color */
    /* font-size: 2rem;
    margin: 0; */
    font-weight: bolder;
    font-size: 3rem;
 text-decoration: none;
    font-weight: 900;
    text-align: left;
  }
  
  .custom-banner-heading-secondary {
    color: #003366; /* Blue color */
    /* font-size: 1.8rem; */
    /* margin: 10px 0; */
    font-size: 2rem;
    text-decoration: none;
       font-weight: 900;
       text-align: left;
       margin-bottom: 0px;
  }
  
  .custom-banner-description {
    color: #666;
    font-size: 1rem;
    /* margin-bottom: 15px; */
  }
  
  .custom-banner-button {
    background-color: #273B60;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    font-weight: 600;
  }
  
  .custom-banner-button:hover {
    background-color: #3b82f6;
  }
  
  .custom-banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-banner-img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  