 /* body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }  */
  
  .content-cart-container {
    width: 80%;
    max-width: 1000px;
    /* margin: 20px auto; */
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    padding: 20px;
  }
  
  /* .header {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
  } */
  
  .subheader {
    font-size: 22px;
    margin-top: 10px;
  }
  
  /* .zip-code {
    background-color: #f3f3f3;
    padding: 10px;
    margin: 15px 0;
  }
  
  .zip-code button {
    background-color: #0071ce;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  } */
  
  .cart-item {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    border-bottom: 1px solid #d8d5d5;
    padding-bottom: 15px;
  }
  
  .product-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: 4px;
    object-fit: cover;
  }
  
  .product-details {
    flex: 1;
  }
  
  .product-details h3 {
    margin: 0 0 5px;
    font-size: 16px;
  }
  
  .product-details p {
    margin: 0;
    color: #888;
  }
  
  .actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .quantity-button {
    background-color: #0071ce;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .product-price {
    text-align: right;
    margin-left: 20px;
  }
  
  .product-price .price {
    font-size: 18px;
    font-weight: bold;
  }
  
  .product-price .discount {
    color: red;
    font-size: 14px;
  }
  
  .product-actions {
    text-align: right;
    margin-left: 20px;
  }
  
  .product-actions a {
    color: #0071ce;
    text-decoration: none;
    display: block;
    margin: 5px 0;
    cursor: pointer;
  }
  
  .product-actions a:hover {
    text-decoration: underline;
  }
  
  .quantity-selector {
    display: flex;
    align-items: center;
    border: 1px solid  #273B60;
    border-radius: 20px;
    padding: 5px 10px;
    width: fit-content;
  }
  
  .quantity-button {
    background: none;
    border: none;
    color:  #273B60;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .quantity-button:hover {
    background-color: #f3f3f3;
  }
  
  .quantity-value {
    font-size: 14px;
    margin: 0 10px;
    color: #333;
  }
  
  .cart-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* position: fixed; */
    bottom: 20px;
    right: 20px;
  }
  
  .clear-cart-button,
  .checkout-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .clear-cart-button {
    background-color: #FF4D00;
  }
  
  .clear-cart-button:hover {
    background-color: #FF4D00;
  }
  
  .checkout-button {
    background-color:  #273B60;
  }
  
  .checkout-button:hover {
    background-color:  #273B60;
  }
  
  .dustbin-icon {
    font-size: 18px;
    color: red;
    cursor: pointer;
  }
  
  .dustbin-icon:hover {
    color: red;
  }

  /* background: #FF4D00; */

  /* .content-cart-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .cart-items-container {
    flex: 2;
  } */
  
  .summary-card {
    /* flex: 1; */
    border: 1px solid #ccc;
    max-width: 300px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  height: 60vh;
  margin-bottom: 20px;
  }
  
  .summary-card h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .summary-card .order-total {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .summary-card button {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 30px;
  }
  
  /* .summary-card .checkout-button {
    background-color: red;
    color: #fff;
  } */
  
  .summary-card .shopping-button {
    background-color: blue;
    color: #fff;
  }
  
  .summary-card .summary-icons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    /* width: 30px; */
    /* height: 30px; */
  }
  
  .summary-card .summary-icons div {
    text-align: center;
    /* font-size: 12px; */
    color: #666;
  }
  .img-cart-icon{
    width: 50px;
    height: 50px;
  }
  .sections{
    display: flex;
    justify-content: space-evenly;
    
  }
  /* p{
    font-size: 10px;
  } */