/* style.css */

/* Modal overlay to cover the entire screen */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it sits above everything else */
  }
  
  /* Modal content styles */
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    max-width: 100%;
    width: 1000px;
    height: 80%; /* Increased height */
    overflow-y: auto; /* Scroll if content overflows */
    position: relative;
  }
  /* Close button styling */
.modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s ease-in-out;
  }
  
  .modal-close-btn:hover {
    color: #ff0000; /* Optional: Red on hover for better UX */
  }
  .prod-description{
    margin-bottom: 0px;
  }
  .prod-wrapper {
    border-radius: 8px;
    position: relative;
    border: 2px solid #dedede;
    padding: 15px;
    transition: transform 0.3s ease;
    /* height: 50%; */
  
  }
  .prod-loop{
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .prod-wrapper:hover {
    transform: scale(1.05);
  }
  .prod-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .img img {
    width: 150%;
    object-fit: contain;
  }
  
  .prod-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .prod-info button {
    position: absolute;
    top: 10px; /* Adjust this based on the positioning */
    right: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
  }
  
  .prod-info .line-through {
    font-size: 0.85rem;
    color: #999;
  }
  
  .prod-info .bg-orange-500 {
    background-color: #F16427; /* Same color as the image's add button */
    border-radius: 20px;
    padding: 4px 10px;
  }
  

  .add-cart-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f5f5f5; /* Light background to match the container */
    border-radius: 20px; /* Rounded corners */
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border: 1px solid #dedede;
  }
  
  .add-cart-btn span {
    font-size: 30px;
    color: #F16427; /* Orange text color */
  }
  
  .add-cart-btn:hover {
    background-color: #ececec; /* Slight background change on hover */
    cursor: pointer;
  }
  

  .add-to-cart-btn {
    background-color: #F16427; /* Blue background color */
    color: #ffffff; /* White text color */
    font-weight: bold; /* Bold text */
    border: none; /* Remove border */
    border-radius: 30px; /* Fully rounded corners */
    padding: 10px 20px; /* Vertical and horizontal padding */
    font-size: 13px; /* Adjust font size */
    text-align: center; /* Center align text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .add-to-cart-btn:hover {
    background-color: #F16427; /* Darker blue on hover */
  }
  

  /* .prod-card{
    height: 50%;
    display: flex;
    flex-direction: column;
  } */