.container {
  font-family: Arial, sans-serif;
  width: 100%;
}

.mainContent {
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 100px;
}

.logo {
  height: 50px;
}

.logoutButton {
  background-color: #ff6b00;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.subtitle {
  color: #7f7f7f;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.card {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.cardTitle {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.cardSubtitle {
  font-size: 14px;
  color: #7f7f7f;
  margin: 10px 0;
}

.imageUploadContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imagePlaceholder {
  background-color: #eaeaea;
  height: 150px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.uploadedImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.imageText {
  font-size: 12px;
  color: #7f7f7f;
}

.fileInput {
  margin-top: 10px;
  padding: 10px;
}

.continueButton {
  background-color: #ff6b00;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.checkboxContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.createAccountButton {
  background-color: #ff6b00;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.termsText {
  font-size: 12px;
  color: #7f7f7f;
  margin-top: 10px;
  text-align: center;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.dayCheckbox {
  display: flex;
  align-items: center;
}

.timeInputContainer {
  display: flex;
  align-items: center;
}

.timeText {
  font-size: 14px;
  color: #333;
}

.okButton {
  background-color: #ff6b00;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.toggle-option {
  flex: 1;
  padding: 0.5rem 1.5rem;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #273B60;
}

.toggle-option.active {
  background-color: #ff4d00;
  border-radius: 30px;
  flex: 1;
  padding: 0.5rem 1.5rem;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

