.claim-business-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .new-search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .new-search-bar input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
  }
  
  .new-search-bar .search-button {
    padding: 5px 20px;
    background-color: orange;
    border: none;
    border-radius: 0 5px 5px 0;
    color: white;
    cursor: pointer;
  }
  
  .business-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .business-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: relative;
    text-align: center;
  }
  
  .status-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #273B60;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
  }
  
  .business-image {
    height: 150px;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #aaa;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .img-bzns{
    height: 150px;
    width: 100%;
  }
  .business-name {
    font-size: 18px;
    font-weight: bold;
    color: #273B60;
  }
  
  .business-address {
    font-size: 14px;
    color: #555;
    margin: 10px 0;
  }
  
  .business-rating {
    font-size: 16px;
    font-weight: bold;
    color: #273B60;
  }
  
  .business-status {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .status-text {
    color: #f76300;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .log-img{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-message{
    font-style: italic;
    font-size: 20px;
  }
  .popup-button{
    background-color: #f76300;
    margin-right: 10px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    width: 80px;
    height: 30px;
    margin-top: 30px;
  }