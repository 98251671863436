.tag-component {
    position: absolute;
    top: 15%; 
    width: 30%;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    z-index: 10;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: auto;
    font-family: Arial, sans-serif;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  

  .tag {
    background-color: #f1f1f1;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .tag:hover {
    background-color: #273B60;
    color: white;
    transform: scale(1.1);
  }
  
  /* .tag {
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  } */
  
  .tag.selected {
    background-color: #273B60;
    color: white;
  }
  