.toggle-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
}

.status-text {
    font-size: 18px;
    font-weight: bold;
}

.online {
    color: #27ae60; /* Green for Online */
}

.offline {
    color: #c0392b; /* Red for Offline */
}

/* Toggle Switch */
.toggle-switch {
    width: 50px;
    height: 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 3px;
    transition: background 0.3s ease-in-out;
    position: relative;
}

.toggle-switch.online {
    background: #a5d6a7; /* Light Green */
}

.toggle-switch.offline {
    background: #e57373; /* Light Red */
}

.toggle-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.toggle-switch.online .toggle-circle {
    transform: translateX(25px);
    background: #27ae60; /* Dark Green */
}

.toggle-switch.offline .toggle-circle {
    transform: translateX(0);
    background: #c0392b; /* Dark Red */
}
