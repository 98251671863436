/* Header Container */
.header-container {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px;
    background-color: #f15d1e;
    width: 80%;
  }
  
  /* Location Component */
  .location {
    color: white;
    font-weight: bold;
    cursor: pointer;
    /* background-color: #37475a; */
    padding: 8px 12px;
    border-radius: 10px;
  }
  
  /* Search Bar Styling */
  .search-bar {
    display: flex;
    flex: 1;
    max-width: 800px;
    background-color: white;
    border-radius: 3px;
  }
  
  .search-bar input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 3px 0 0 3px;
    outline: none;
  }
  
  .search-bar button {
    padding: 10px;
    background-color: #febd69;
    border: none;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: white;
    /* width: 400px; */
    margin: 100px auto;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    text-align: center;
  }
  
  .postal-input {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .postal-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .postal-input button {
    padding: 8px 12px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .done-btn {
    background-color: #febd69;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  