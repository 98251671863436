/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body {
    font-family: 'Poppins', sans-serif;
    background-color: #F5EFE7;
} */

.app-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #F5EFE7; /* Main background color */
}


/* Sidebar Styles */
.sidecontainer {
    background: linear-gradient(180deg, #213555, #1B2A4D);
    height: 100vh;
    width: 20%;
    color: #F5EFE7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: width 0.3s ease;
    height: 100vh;
}

.sidecontainer.collapsed {
    width: 100px;
}

/* Toggle Button */
.toggle-btn {
    position: absolute;
    top: 20px;
    right: -20px;
    background-color: #213555;
    color: #F5EFE7;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}


/* Admin Profile Styles */
.admin-profile {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Divider line */
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.admin-avatar {
    width: 50px; /* Fixed size for avatar */
    height: 50px;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image fits properly */
    border: 2px solid #F5EFE7; /* Optional border for style */
    margin-right: 15px;
}

.sidecontainer.collapsed .admin-avatar {
    margin-right: 0; /* Remove margin for better alignment */
}

.admin-info {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.sidecontainer.collapsed .admin-info {
    opacity: 0;
    pointer-events: none;
}

/* Sidebar Menu Styles */
.sidebar-list {
    list-style: none;
}

.sidebar-list li {
    margin-bottom: 20px;
}

.sidebar-list li a {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    padding: 15px 10px;
    color: #F5EFE7;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    transition: all 0.3s ease;
    background: transparent;
}

.sidecontainer.collapsed .sidebar-list li a {
    justify-content: center;
    gap: 0;
}

.sidebar-list li a:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #D8C4B6;
}

.sidebar-list li a img {
    width: 30px; /* Consistent size for icons */
    height: 30px;
    filter: brightness(0.9);
    transition: all 0.3s ease;
}

.sidebar-list li a:hover img {
    filter: brightness(1);
}

.sidebar-list li a span {
    transition: opacity 0.3s ease;
}

.sidecontainer.collapsed .sidebar-list li a span {
    opacity: 0;
    pointer-events: none;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sidecontainer {
        width: 30%;
    }

    

    .admin-avatar {
        width: 40px; /* Smaller avatar for smaller screens */
        height: 40px;
    }

    .sidebar-list li a {
        font-size: 0.9rem;
    }
}

.logout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background:#F16427;
    color: white;
    border: none;
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    font-size: 20px;
  }
  
  .logout-btn img {
    margin-right: 10px;
  }
  
  .logout-btn:hover {
    background: #f84e06;
  }
  