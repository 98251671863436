.date-range-container {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    max-width: 350px;
  }
  
  .date-range-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    color: #273B60;
  }
  
  .arrow {
    font-size: 18px;
    transition: transform 0.3s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .date-range-content {
    margin-top: 10px;
  }
  
  .date-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  
  .vertical-line {
    position: absolute;
    left: 5px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #b0b0b0;
  }
  
  .date-input {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .date-input label {
    font-size: 14px;
    color: #777;
  }
  
  .date-input input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
  }
  
  .date-range-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .clear-btn {
    background: #273B60;
    border: none;
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .apply-btn {
    background: #FF4D00;
    border: none;
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .apply-btn:hover {
    background: #FF4D00;
  }
  