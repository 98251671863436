.add-product-container {
  max-width: 600px;
  margin: 2em auto;
  background-color: #f9f9f9;
  padding: 2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em !important;
  font-weight: bolder;
  color: #273b60 !important;
}

.add-product-content {
  width: 90%;
  max-width: 500px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.add-product-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.collapsible-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.section-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  background-color: #eee;
  border-radius: 4px;
}

.section-content {
  padding: 15px;
}

.section-content-title {
  font-size: 1em;
  font-weight: bold;
  color: #273b60;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.preview-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.add-product-button {
  width: 100%;
  padding:0.5em;
  background-color: #ff4d00;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.3em !important;
}

.add-product-button:hover {
  background-color: #e84300;
}


.business-products-button {
  width: 100%;
  padding: 0.5em;
  background-color: #273b60;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.3em !important;

}

.business-products-button:hover {
  background-color: #1d2c49;
}
/* category css  */
.add-category-btn {
  background-color: #ff4d00;
  color: white;
  border: none;
  width: 13%;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  font-weight: bolder;
  padding: 0.3em; 
}

.plus{
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-category-modal {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 70%;
  margin: 1em auto;
}

.buttons-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.add-btn {
  background-color: #ff4d00;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  width: 50%;
}

.cancel-btn {
  background-color: #273b60;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  width: 50%;
}


.category-dropdown{
  display: flex;
  align-items: center;
  justify-content: center;
}
