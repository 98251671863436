.delete-account-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .delete-account-form .form-group {
    margin-bottom: 15px;
  }
  
  .delete-account-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .delete-account-form input,
  .delete-account-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .delete-button {
    width: 100%;
    padding: 12px;
    background-color: #e74c3c;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }
  