/* WhyChoose.css */

.why-choose-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 3rem;
    gap: 2rem;
    background-color: #fff;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .why-choose-left {
    flex: 1;
  }
  
  .why-choose-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .why-choose-right {
    flex: 1;
    text-align: left;
  }
  
  .why-choose-heading {
    text-align: center;
    /* margin-bottom: 1.5rem; */
  }
  
  .why-choose-heading h2 {
    font-size: 2rem;
    margin: 0;
  }
  
  .orange-text {
    color: #f76300; /* Orange color */
    font-weight: bold;
    font-weight: bolder;
    font-size: 2rem;
 text-decoration: none;
    font-weight: 900;
  }
  
  .blue-text {
    color: #003366; /* Blue color */
    font-weight: bold;
    font-size: 2rem;
    text-decoration: none;
       font-weight: 900;
  }
  
  .subheading {
    font-size: 1.5rem;
    color:#003366;
    margin: 0.5rem 0 2rem;
    text-align: center;
    font-weight: 600;
  }
  
  .features {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: left;
    justify-content: left;
  }
  
  .feature-item {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 1rem;
  }
  
  .feature-number {
    background-color: #f76300; /* Orange color */
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 10%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .feature-item p {
    margin: 0;
    color: #4a4a4a;
  }
  