.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh; /* Adjust height as needed */
    flex-direction: column;
    width: 100%;
  }
  
  .video-container {
    width: 100%; /* Adjust the size of the video */
    max-width: 900px; /* Optional: Add a max width */
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  }
  /* About Us Section */
.about-container {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* background-color: #f7f7f7; */
    padding: 3rem 2rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    gap: 2rem;
    width: 100%;
    justify-content: space-evenly;
  }
  
  
  .about-text {
    max-width: 500px;
  }
  
  .tajam-logo {
    /* width: 120px; */
    /* margin-bottom: 1rem; */
  }
  
  h2.welc {
    font-size: 2.5rem;
    color: #273B60;
    font-weight: 800;
    margin-bottom: 0.5rem;
  }
  
  h2 .welcome-text {
    font-weight: 800;
  }
  
  h2 .tajam-text {
    font-weight: bolder;

  }
  
  .highlight-text {
    font-size: 2.5rem;
    color: #F16427;
    margin-bottom: 1rem;
    font-weight: 800;
    
  }
  
  .description-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  .join-btn {
    background-color: #273B60;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    font-weight: 600;
  }
  
  .join-btn:hover {
    background-color: #F16427;
  }
  
  /* Image on the Right */
  .about-image img {
    max-width: 100%;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      text-align: center;
    }
  }


  .welcome-text{
    text-decoration: none;
  }

  /* Brand Story Container */
.brand-story-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    gap: 2rem;
    padding: 2rem;
    /* background-color: #f7f7f7; */
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    justify-content: space-evenly;
  }
  .brand-story-content{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  /* Image Section */
  .brand-story-image img {
    width: 300px;
    max-width: 100%;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  /* Text Section */
  .brand-story-text {
    max-width: 500px;
    color: #273B60;
  }
  
  .brand-story-text h2 {
    /* font-size: 2rem; */
    margin-bottom: 0.5rem;
    /* color: #273B60; */
    font-size: 2.5rem;
    color: #273B60;
    font-weight: 800;
  }
  
  .brand-highlight {
    color: #F16427;
    font-weight: bold;
    font-size: 2.5rem;
 
    font-weight: 800;
  }
  
  .story-title {
    font-weight: bold;
    color: #273B60;
    font-weight: 800;
  }
  
  .brand-story-text h3 {
    font-size: 1.5rem;
    /* color: #273B60; */
    /* margin-bottom: 1rem;
    font-size: 2.5rem; */
    color: #273B60;
    font-weight: 700;
  }
  
  .brand-story-text p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .brand-story-container {
      flex-direction: column;
      text-align: center;
      gap: 1rem;
    }
  }
  

  
  /* Container */
.why-tajjam-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    /* text-align: center; */
  }
  
  .why-tajjam-heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .why-tajjam-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .highlight-why {
    color: #F16427; /* Highlight color */

    font-weight: bold;
    font-size: 2.5rem;
 
    font-weight: 800;
  }
  .highlight-blue {
    color: #273B60; /* Highlight color */

    font-weight: bold;
    font-size: 2.5rem;
 
    font-weight: 800;
  }
  /* Wrapper */
  .why-tajjam-content-wrapper {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
  }
  
  /* Left Side - Images */
  .why-tajjam-images {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
  }
  
  .collage-row {
    display: flex;
    gap: 1rem;
  }
  
  .collage-img {
    width: 40%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  /* Right Side - Points Grid */
  .why-tajjam-text {
    margin-top: 100px;
    width: 50%;
  }
  
  .why-tajjam-points-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .why-tajjam-points-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .why-tajjam-point {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    flex-direction: column;
    width: 48%;
  }
  
  .number-box {
    background-color:#273B60;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 4.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
  }
  
  .why-tajjam-point h3 {
    font-size: 1.1rem;
    margin: 0;
  }
  
  .why-tajjam-point p {
    font-size: 0.9rem;
    color: #666;
    margin: 0.5rem 0 0;
  }
  

  /* customer prespective css  */
  /* Container */
.customer-aboutus-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 3rem auto;
    padding: 1rem;
    max-width: 1200px;
  }
  
  /* Left Content */
  .customer-aboutus-text {
    flex: 1;
    max-width: 500px;
    text-align: left;
  }
  
  .customer-aboutus-heading {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .highlight-customer {
    color: #f36f24; /* Orange */
    font-weight: bold;
    font-size: 2.5rem;
 
    font-weight: 800;
  }
  
  .customer-aboutus-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .customer-aboutus-button {
    background-color: #273B60;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    font-weight: 600;
  }
  
  .customer-aboutus-button:hover {
    background-color: #457b9d; /* Lighter Blue */
  }
  
  /* Right Image */
  .customer-aboutus-image-wrapper {
    flex: 1;
    text-align: center;
  }
  
  .customer-aboutus-image {
    /* max-width: 80%; */
    /* height: 80%; */
    margin-left: 150px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .customer-aboutus-section {
      flex-direction: column;
      text-align: center;
    }
  
    .customer-aboutus-text {
      max-width: 100%;
    }
  
    .customer-aboutus-heading {
      font-size: 2rem;
    }
  
    .customer-aboutus-description {
      font-size: 0.9rem;
    }
  }
  

  .download-app-section {
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: center;
    justify-content: space-between;
    gap: 150px;
    padding: 50px 10%;
    /* background-color: #f9f9f9; */
  }
  
  /* Left Side: Image */
  .download-app-image img {
    max-width: 100%;
    height: auto;
  }
  
  /* Right Side: Content */
  .download-app-content {
    flex: 1;
    text-align: left;
  }
  
  .logos-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    /* margin-bottom: 10px; */
  }
  
  .logo,
  .bag-logo {
    height: 50px;
  }
  
  .main-heading {
    font-size: 2rem;
    font-weight: 700;
    color: #192343;
    /* margin: 10px 0; */
  }
  
  .download-heading {
    font-size: 3rem;
    font-weight: 800;
    color:black;
    /* margin: 10px 0 20px; */
  }
  
  .download-description {
    font-size: 1rem;
    color: #192343;
    line-height: 1.6;
    margin-bottom: 0px;
  }
  
  /* Download Buttons and QR Codes Row */
  .download-wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  /* App Buttons */
  .store-buttons {
    display: flex;
    gap: 20px;
    margin-top: 0px;
  }
  
  .store-button img {
    height: 120px; /* Larger Buttons */
  }
  
  /* QR Codes */
  .qr-codes {
    display: flex;
    gap: 15px;
  }
  
  .qr-code {
    height: 50px; /* Smaller QR Code */
    width: 50px;
  }
  