.business-grid-container {
    text-align: center;
    padding: 2rem;
    background-color: #f8f9fa;
    /* max-width: 1600px; */
    margin: 0 auto;
    width: 100%;
  }
  
  h2.business-grid-title {
    /* font-size: 2rem; */
    /* color: #f76300; */
    font-weight: bold;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    /* font-weight: bold; */
    color: #f76300; /* Orange color */
    margin-bottom: 0.5rem;
    font-weight: bolder;
    /* font-size: 4rem; */
 text-decoration: none;
    font-weight: 900;
  }
  
  .business-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 1.5rem; /* Spacing between grid items */
    justify-content: center; /* Center-align grid items */
  }
  
  @media (max-width: 768px) {
    .business-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
  }
  
  @media (max-width: 425px) {
    .business-grid {
      grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
    }
  }
  
  
  .business-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .business-grid-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 0.5rem;
  }
  
  .business-grid-text {
    color: #003366; /* Blue color */
    /* font-size: 1.8rem; */
    /* margin: 10px 0; */
    font-size: 1.5rem;
    text-decoration: none;
       font-weight: 900;
  }
  
  .business-grid-button {
    margin-top: 2rem;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #273b60;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .business-grid-button:hover {
    background-color: #f76300;
  }
  