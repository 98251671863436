.products-main-container{
  display: flex;
  padding: 2em;
  gap: 2em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.product-head {
  margin-top: 1em;
  text-decoration: none;
  color: #273b60;
  font-weight: bold;
  font-size: 2em;
}

.all-business-products {
  display: flex;
  padding: 2em;
  gap: 2em;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.categories-head {
  color: #273b60;
  font-weight: bold;
  text-decoration: none;
}
.categories {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
}

.category-btn {
  padding: 0.5em;
  border-radius: 8px;
  background-color: #273b60;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: capitalize;
  width: 90%;
}

.category-btn.active {
  background-color: #ff4d00;
  color: #ffffff;
}

.category-btn.active:hover {
  background-color: #e84300;
  color: #ffffff;
}
.category-btn:hover {
  background-color: #1d2c49;
  color: #ffffff;
}

.products-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1em;
}

.product-card {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(122, 117, 117, 0.5);
  transition: transform 0.3s;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.business-product-info{
  padding: 1em;
  border-radius: 10px;
  width: 100% !important;
}

.business-product-image {
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px;
}

.business-product-image img{
  width: 100% !important;
  height: 20vh;
  object-fit: cover;
  border-radius: 10px;
}

.business-product-details ,
.business-product-header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #273b60;
  width: 100%;
}

.product-name {
  color: #273b60;
  font-size: 1.1em;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: bolder !important;
  margin-top: 0.5em;
}

.product-description {
  color: #273b60;
  font-size: 14px;
  margin: 5px 0;
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-y: auto !important; /* Add vertical scroll for overflow */
  max-height: 4.5em; /* Limit the height */
  line-height: 1.5em; /* Adjust line height */
  padding-right: 5px; /* Add padding for scroll visibility */
}

.business-product-info-details p{
  color: #273b60;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button .btn {
  margin-top: 10px;
  padding: 8px 12px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  font-size: 1.1em;
  background-color: #273b60 !important;
}


.no-products-found {
  text-align: center;
  color: #273b60;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-products-found img {
  height: 50vh;
}

.no-products-found p {
  font-size: 1.2em;
  color: #555;
}
